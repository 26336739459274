<template>
  <div class="right">
    <span class="spen">我的购物卡</span>
    <div class="menu">
      <div class="qiehuan">
        <div
          class="daohang"
          :class="[
            { bian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in paihang"
          :key="index"
          @click="selectedTabItem(index, item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="search">
        <input type="text" v-model="mes" placeholder="输入购物卡id或订单号" />
        <div class="fangdajing" @click="search">
          <Uimage src="../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div>
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <el-table :data="data1" style="width: 100%">
        <el-table-column prop="storeName" :label="$t('common.shop')" width="230">
        </el-table-column>
        <el-table-column prop="name" label="购物卡名称"> </el-table-column>
        <el-table-column prop="cardCode" label="ID"> </el-table-column>
        <el-table-column prop="orderAmount" label="订单金额"> </el-table-column>
        <el-table-column prop="discountAmount" label="购物卡面值">
        </el-table-column>
        <el-table-column prop="orderNo" :label="$t('label.Order_number')"> </el-table-column>
        <el-table-column prop="updateTime" label="使用时间"> </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="pagechange"
      :current-page="page.current"
      style="text-align: right;margin-top: 20px"
      background
      :total="page.total"
      :page-size="page.size"
      layout="prev, pager, next, jumper, ->, total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      paihang: [
        { id: 1, title: '已使用' },
        { id: 3, title: '可使用' },
        { id: 2, title: '已过期' },
      ],
      TabIndex: 0,
      status: 1,
      TabList: [],
      data1: [],
      data2: [],
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      isLoading: true,
      mes: '',
    };
  },
  created() {
    this.search();
  },
  methods: {
    selectedTabItem(index, e) {
      this.isLoading = true;
      this.TabIndex = index;
      this.status = e;
      get(
        'api/shopcoupon/selectShopCardRecord?mes=' +
          this.mes +
          '&pageNum=' +
          this.page.current +
          '&pageSize=' +
          this.page.size +
          '&status=' +
          this.status
      ).then((res) => {
        let data = res.data.data;
        this.data1 = data.list;
        this.isLoading = false;
        this.page.total = data.total;
      });
    },
    search() {
      this.isLoading = true;
      get(
        'api/shopcoupon/selectShopCardRecord?mes=' +
          this.mes +
          '&pageNum=' +
          this.page.current +
          '&pageSize=' +
          this.page.size +
          '&status=' +
          this.status
      ).then((res) => {
        let data = res.data.data;
        this.data1 = data.list;
        this.isLoading = false;
        this.page.total = data.total;
      });
    },
    pagechange(p) {
      this.isLoading = true;
      this.page.current = p;
      get(
        'api/shopcoupon/selectShopCardRecord?mes=' +
          this.mes +
          '&pageNum=' +
          p +
          '&pageSize=' +
          this.page.size +
          '&&status=' +
          this.status
      ).then((res) => {
        let data = res.data.data;
        this.data1 = data.list;
        this.isLoading = false;
        this.page.total = data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 976px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  padding-right: 30px;

  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }

  .yue {
    width: 100%;
    height: 130px;
    margin-top: 63px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .yue_L {
      font-size: 26px;
      color: #777;
      font-weight: 600;

      .big {
        font-size: 46px;
      }
    }

    .yue_C {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .quanbu {
        display: flex;
        font-size: 16px;

        img {
          width: 20px;
          height: 20px;
        }

        .quanbu_Y1 {
          color: #333;
          margin-left: 15px;
        }

        .quanbu_Y2 {
          color: #545454;
          margin-left: 20px;
        }
      }
    }

    .yue_R {
      width: 112px;
      height: 38px;
      background: #1850ff;
      color: #fff;
      line-height: 38px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    .qiehuan {
      margin-top: 60px;
      height: 42px;
      display: flex;
      align-items: center;
      cursor: default;

      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 25px;
        cursor: pointer;
      }

      .bian {
        color: #1a4fff;
      }
    }

    .jilu {
      margin-top: 60px;
      color: #363636;
    }

    .search {
      margin-right: 150px;
      display: flex;

      input {
        border: 0;
        outline: none;
        border: 1px solid #a7a7a7;
        height: 37px;
        padding-left: 10px;
      }

      .fangdajing {
        width: 45px;
        height: 40px;
        border: 1px solid #a7a7a7;
        border-left: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .tishi {
    font-size: 14px;
    color: #929292;
    margin-top: 10px;
  }
}
</style>
